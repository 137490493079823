import React from "react";
import ContactForm from "./components/ContactForm";

function Contact() {
	return (
		<>
			<ContactForm />
		</>
	);
}

export default Contact;
